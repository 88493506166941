import React from "react";

const chunkArray = (array, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const Features = (props) => {
  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="col-md-10 col-md-offset-1 section-title">
          <h2>Features</h2>
        </div>
        {props.data
    ? chunkArray(props.data, 4).map((chunk, rowIndex) => (
        <div className="row" key={`row-${rowIndex}`}>
          {chunk.map((d, i) => (
            <div key={`${d.title}-${i}`} className="col-xs-12 col-md-3" >
              <i className={d.icon}></i>
              <h3 style={{minHeight:"45px"}}>{d.title}</h3>
              <p style={{textAlign:"justify"}}>{d.text}</p>
            </div>
          ))}
        </div>
      ))
    : "Loading..."}
      </div>
    </div>
  );
};
