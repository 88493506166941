import { useState } from "react";
// import emailjs from "emailjs-com";
import React from "react";
import { OtpPopup } from "./otpPopup";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);
  const [showPopup, setShowPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    
    if (!name || !email || !message) return alert("Please fill out required fields");
    {/* replace below with your own Service ID, Template ID and Public Key from your EmailJS account */ }    
    
    setShowPopup(true);
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}/email.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify({
        from: email,
        subject: name,
        message: message,
        action: 'send_otp',
      }),
    })
      .then((res) => {
        if (res.status !== 200) {
          alert(`Something went wrong, Please try again`);
          return;
        }
        alert('Please check your email for OTP');
      })
      .catch((err) => {
        alert(`Something went wrong, Please try again`);
      });
  };

  const handleOnSent = (otp) => {
    console.log("OTP sent", otp);
    
    fetch(`${process.env.REACT_APP_BACKEND_URL}/email.php`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify({
        otp: Number(otp),
        action: 'verify_otp',
      }),
    })
      .then((res) => {
        setShowPopup(false);
        if (res.status !== 200) {
          alert(`Something went wrong, Please try again`);
          return;
        }
        alert("Your message has been sent successfully");
      })
      .catch((err) => {
        setShowPopup(false);
        alert(`Something went wrong, Please try again`);
      });
  };

  return (
    <div>      
      <div id="contact">
        <input type="hidden" value="hello" />
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
                <p>
                Please fill out the form below. After submitting, you will receive an OTP via email. Enter the OTP to complete your submission, and we will get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-controltxtarea"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Submit
                </button>
              </form>
              {showPopup && <OtpPopup onClose={handleOnSent} />}
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div> */}
            {/* <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div> */}
          </div>
          {/* <div className="col-md-12">
            <div className="row" style={{display:'none'}}>
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
          Copyright© 2024 - All Rights Reserved            
          </p>
        </div>
      </div>
    </div>
  );
};
