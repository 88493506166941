import React, { useState } from "react";

export const OtpPopup = ({ onClose }) => {
  const [otp, setOtp] = useState("");

  const handleOnChange = (e) => {
    const value = e.target.value?.trim();
    if (!value) return;
    setOtp(e.target.value);
  };  

  const handleSubmit = () => {
    onClose(otp); // Pass the OTP value back to the parent
  };
  
  return (
    <div className="otp-popup-wrapper" style={{marginTop:"-65px"}}>
      <h3>OTP</h3>
      <div className="row" style={{marginTop:"-25px"}}>
        <div className="col-md-6" style={{marginBottom:"10px"}}>
          <input type="text"
            id="name"
            name="name"
            className="form-control"
            placeholder="Enter OTP"
            required
            onChange={handleOnChange}
            value={otp}
          />
        </div>
        <div className="col-md-6">
          <button type="button" onClick={handleSubmit} className="btn btn-custom btn-lg" >
            Verify & Send
          </button>
        </div>
      </div>
    </div>
  );
};
